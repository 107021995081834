.imageContainer {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(0, 0, 0, 0.4);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; /* Centra horizontalmente los elementos hijos */
}

.imageContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/332320709_929341091427566_3086844240419480818_n.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 40%;
    z-index: -1;
}

.h1 {
    font-size: 72px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: relative;
}

.h2 {
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #fff;
    text-align: center;
    position: relative;
    margin-left: 200px;
    margin-right: 200px;
}

.h3 {
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #000000;
    text-align: center;
}

.h4 {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #000000;
    text-align: justify;
}

.empoderandoContainer {
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(255, 204, 0, 0.4);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; 
}

.empoderandoContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/IMG_1148.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.h5 {
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
    color: #fff;
    text-align: center;
    position: relative;
    margin-left: 200px;
    margin-right: 200px;
}

.ministeriosContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(116, 164, 236, 0.463);
    margin-top: 50px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 30px;
}

.h7 {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: bold;
    color: #000000;
}

.p {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: normal;
    color: #000000; 
    text-align: center;
}

.ministeriosDescripcionContainer {
    display: flex;
    align-items: center;
    gap: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 50px;
    margin-left: 50px;
    background-color: aliceblue;
    padding-left: 80px;
}

.ministeriosDescripcionContainer {
    position: relative;
    overflow: hidden;
}

.ministeriosDescripcionContainer img {
    width: 630px;
    border: 15px solid rgba(116, 164, 236, 0.463);
    border-radius: 10px;
}

.ministeriosDescripcionContainer2 {
    display: flex;
    align-items: center;
    gap: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 50px;
    margin-left: 50px;
    background-color: aliceblue;
    padding-right: 80px;
}

.ministeriosDescripcionContainer2 {
    position: relative;
    overflow: hidden;
}

.ministeriosDescripcionContainer2 img {
    width: 630px;
    border: 15px solid rgba(116, 164, 236, 0.463);
    border-radius: 10px;
}

.ministeriosDescripcionContainer img:hover,
.ministeriosDescripcionContainer2 img:hover {
    transform: scale(1.15);
    transition: transform 0.2s ease-in-out;
}

@media (max-width: 1024px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 500px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.4);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/332320709_929341091427566_3086844240419480818_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 40%;
        z-index: -1;
    }
    
    .h1 {
        font-size: 68px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 28px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .h3 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
        text-align: center;
    }
    
    .h4 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
    }
    
    .empoderandoContainer {
        font-family: 'Roboto Condensed', sans-serif;
        position: relative;
        width: 100%;
        height: 600px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(255, 204, 0, 0.4);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .empoderandoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/IMG_1148.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .h5 {
        font-size: 44px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 150px;
        margin-right: 150px;
    }
    
    .ministeriosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(116, 164, 236, 0.463);
        margin-top: 50px;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 30px;
    }
    
    .h7 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #000000;
    }
    
    .p {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: #000000; 
        text-align: center;
    }
    
    .ministeriosDescripcionContainer {
        display: flex;
        align-items: center;
        gap: 80px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-right: 30px;
        margin-left: 30px;
        background-color: aliceblue;
        padding-left: 80px;
    }
    
    .ministeriosDescripcionContainer {
        position: relative;
        overflow: hidden;
    }
    
    .ministeriosDescripcionContainer img {
        width: 530px;
        border: 15px solid rgba(116, 164, 236, 0.463);
        border-radius: 10px;
    }
    
    .ministeriosDescripcionContainer2 {
        display: flex;
        align-items: center;
        gap: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-right: 30px;
        margin-left: 30px;
        background-color: aliceblue;
        padding-right: 80px;
    }
    
    .ministeriosDescripcionContainer2 {
        position: relative;
        overflow: hidden;
    }
    
    .ministeriosDescripcionContainer2 img {
        width: 530px;
        border: 15px solid rgba(116, 164, 236, 0.463);
        border-radius: 10px;
    }
    
    .ministeriosDescripcionContainer img:hover,
    .ministeriosDescripcionContainer2 img:hover {
        transform: scale(1.15);
        transition: transform 0.2s ease-in-out;
    }
}

@media (max-width: 768px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 400px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.4);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/332320709_929341091427566_3086844240419480818_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 40%;
        z-index: -1;
    }
    
    .h1 {
        font-size: 50px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .h3 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
        text-align: center;
    }
    
    .h4 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
    }
    
    .empoderandoContainer {
        font-family: 'Roboto Condensed', sans-serif;
        position: relative;
        width: 100%;
        height: 400px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(255, 204, 0, 0.4);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .empoderandoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/IMG_1148.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .h5 {
        font-size: 32px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .ministeriosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(116, 164, 236, 0.463);
        margin-top: 50px;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 30px;
    }
    
    .h7 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
    }
    
    .p {
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: normal;
        color: #000000; 
        text-align: center;
    }
    
    .ministeriosDescripcionContainer {
        display: flex;
        align-items: center;
        gap: 80px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-right: 30px;
        margin-left: 30px;
        background-color: aliceblue;
        padding-left: 80px;
    }
    
    .ministeriosDescripcionContainer {
        position: relative;
        overflow: hidden;
    }
    
    .ministeriosDescripcionContainer img {
        width: 330px;
        border: 15px solid rgba(116, 164, 236, 0.463);
        border-radius: 10px;
    }
    
    .ministeriosDescripcionContainer2 {
        display: flex;
        align-items: center;
        gap: 80px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-right: 30px;
        margin-left: 30px;
        background-color: aliceblue;
        padding-right: 80px;
    }
    
    .ministeriosDescripcionContainer2 {
        position: relative;
        overflow: hidden;
    }
    
    .ministeriosDescripcionContainer2 img {
        width: 330px;
        border: 15px solid rgba(116, 164, 236, 0.463);
        border-radius: 10px;
    }
    
    .ministeriosDescripcionContainer img:hover,
    .ministeriosDescripcionContainer2 img:hover {
        transform: scale(1.15);
        transition: transform 0.2s ease-in-out;
    }
}

@media (max-width: 425px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.4);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/332320709_929341091427566_3086844240419480818_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 40%;
        z-index: -1;
    }
    
    .h1 {
        font-size: 40px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .h3 {
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
        text-align: center;
    }
    
    .h4 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
    }
    
    .empoderandoContainer {
        font-family: 'Roboto Condensed', sans-serif;
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(255, 204, 0, 0.4);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .empoderandoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/IMG_1148.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .h5 {
        font-size: 22px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .ministeriosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(116, 164, 236, 0.463);
        margin-top: 7px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 7px;
    }
    
    .h7 {
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: bold;
        color: #000000;
    }
    
    .p {
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: normal;
        color: #000000; 
        text-align: center;
    }
    
    .ministeriosDescripcionContainer {
        display: flex;
        flex-direction: column;
        margin-top: 7px;
        margin-bottom: 7px;
        background-color: aliceblue;
        gap: 20px;
        padding-left: 0px;
    }
    
    .ministeriosDescripcionContainer {
        overflow: hidden;
    }
    
    .ministeriosDescripcionContainer img {
        width: 300px;
        border: 5px solid rgba(116, 164, 236, 0.463);
        border-radius: 10px;
    }
    
    .ministeriosDescripcionContainer2 {
        display: flex;
        flex-direction: column;
        margin-top: 7px;
        margin-bottom: 7px;
        background-color: aliceblue;
        gap: 20px;
        padding-right: 0px;
    }
    
    .ministeriosDescripcionContainer2 {
        position: relative;
        overflow: hidden;
    }
    
    .ministeriosDescripcionContainer2 img {
        width: 300px;
        border: 5px solid rgba(116, 164, 236, 0.463);
        border-radius: 10px;
    }
    
    /* .ministeriosDescripcionContainer img:hover,
    .ministeriosDescripcionContainer2 img:hover {
        transform: scale(1.15);
        transition: transform 0.2s ease-in-out;
    } */
}