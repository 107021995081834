/* Estilos generales */
.main {
    width: 100%; 
    margin: 0 auto; /* Centrar el contenido */
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ministeriosContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
}

.p {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: normal;
    color: #000000; 
    text-align: center;
}

.redesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal; /* Restablece la negrita para h3 */
}

.h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal; /* Restablece la negrita para h3 */
}

.h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal; /* Restablece la negrita para h3 */
    color: #fff;
}

.h7 {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #000000;
}

.imageCarouselWithMargin {
    margin-right: 110px; /* Margen derecho de 300px */
    margin-left: 110px; /* Margen derecho de 300px */
    background-color: #aac6ff;
    border-radius: 30px;
  }

  .nuevoMiembro {
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    text-align: center;
  }

  .h3Bien {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal; /* Restablece la negrita para h3 */
    font-size: 24px;
  }

  .formacionContainer {
    margin-top: 100px;
    margin-bottom: 0px;
    width: 100%; 
    height: 400px; 
    background-image: url('../assets/IMG_1209\ 2.jpg'); /* Ruta de la imagen de fondo */
    background-size: cover; /* Escala la imagen para que cubra todo el contenedor */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-position: center; /* Centra la imagen en el contenedor */
    align-items: center;
    flex-direction: column;
    display: flex;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Ajusta el color y la opacidad según tus preferencias */
      }
  }

  .h7Form {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #fff; 
    text-align: center;
    position: relative;
}

.h8Form {
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    font-weight: normal;
    color: #fff;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 18%;
}

.enlaceFormacion {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    text-decoration: none; 
    transition: underline 0.3s ease; /* Agrega una transición para suavizar el cambio */
}

.enlaceFormacion:hover {
    text-decoration: underline; /* Subraya el texto al pasar el cursor por encima */
}

.versiculoContainer {
    position: relative;
    width: 100%;
    height: 800px;
    background-color: rgba(52, 77, 214, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.versiculoContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/313406135_437505558548539_1126158959325515491_n.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 55%;
    z-index: -1;
}

.p2 {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: relative;
}

.p3 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #fff;
    text-align: center;
    position: relative;
}

@media (max-width: 1024px) {

    .main {
        width: 100%; 
        margin: 0 auto; /* Centrar el contenido */
    }
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .videoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ministeriosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 50px;
    }
    
    .p {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: #000000; 
        text-align: center;
    }
    
    .redesContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }
    
    .h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
    }
    
    .h5 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
    }
    
    .h6 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
        color: #fff;
    }
    
    .h7 {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #000000;
    }
    
    .imageCarouselWithMargin {
        margin-right: 20px; /* Margen derecho de 300px */
        margin-left: 20px; /* Margen derecho de 300px */
        background-color: #aac6ff;
        border-radius: 30px;
      }
    
      .nuevoMiembro {
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
        text-align: center;
      }
    
      .h3Bien {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
        font-size: 18px;
      }
    
      .formacionContainer {
        margin-top: 50px;
        margin-bottom: 0px;
        width: 100%; 
        height: 300px; 
        background-image: url('../assets/IMG_1209\ 2.jpg'); /* Ruta de la imagen de fondo */
        background-size: cover; /* Escala la imagen para que cubra todo el contenedor */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center; /* Centra la imagen en el contenedor */
        align-items: center;
        flex-direction: column;
        display: flex;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); /* Ajusta el color y la opacidad según tus preferencias */
          }
      }
    
      .h7Form {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #fff; 
        text-align: center;
        position: relative;
    }
    
    .h8Form {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: normal;
        color: #fff;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 18%;
    }
    
    .enlaceFormacion {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        text-decoration: none; 
        transition: underline 0.3s ease; /* Agrega una transición para suavizar el cambio */
    }
    
    .enlaceFormacion:hover {
        text-decoration: underline; /* Subraya el texto al pasar el cursor por encima */
    }
    
    .versiculoContainer {
        position: relative;
        width: 100%;
        height: 550px;
        background-color: rgba(52, 77, 214, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .versiculoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/313406135_437505558548539_1126158959325515491_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 55%;
        z-index: -1;
    }
    
    .p2 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .p3 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {

    .main {
        width: 100%; 
        margin: 0 auto; /* Centrar el contenido */
    }
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .videoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ministeriosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 50px;
    }
    
    .p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: #000000; 
        text-align: center;
    }
    
    .redesContainer {
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }
    
    .h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
    }
    
    .h5 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
    }
    
    .h6 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
        color: #fff;
    }
    
    .h7 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
    }
    
    .imageCarouselWithMargin {
        margin-right: 20px; /* Margen derecho de 300px */
        margin-left: 20px; /* Margen derecho de 300px */
        background-color: #aac6ff;
        border-radius: 30px;
      }
    
      .nuevoMiembro {
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
        text-align: center;
      }
    
      .h3Bien {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
        font-size: 14px;
      }
    
      .formacionContainer {
        margin-top: 50px;
        margin-bottom: 0px;
        width: 100%; 
        height: 200px; 
        background-image: url('../assets/IMG_1209\ 2.jpg'); /* Ruta de la imagen de fondo */
        background-size: cover; /* Escala la imagen para que cubra todo el contenedor */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center; /* Centra la imagen en el contenedor */
        align-items: center;
        flex-direction: column;
        display: flex;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); /* Ajusta el color y la opacidad según tus preferencias */
          }
      }
    
      .h7Form {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #fff; 
        text-align: center;
        position: relative;
    }
    
    .h8Form {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: #fff;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 18%;
    }
    
    .enlaceFormacion {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        text-decoration: none; 
        transition: underline 0.3s ease; /* Agrega una transición para suavizar el cambio */
    }
    
    .enlaceFormacion:hover {
        text-decoration: underline; /* Subraya el texto al pasar el cursor por encima */
    }
    
    .versiculoContainer {
        position: relative;
        width: 100%;
        height: 450px;
        background-color: rgba(52, 77, 214, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .versiculoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/313406135_437505558548539_1126158959325515491_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 55%;
        z-index: -1;
    }
    
    .p2 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .p3 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
    }
}

@media (max-width: 425px) {

    .main {
        width: 100%; 
        margin: 0 auto; /* Centrar el contenido */
    }
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .videoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ministeriosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 50px;
    }
    
    .p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: #000000; 
        text-align: center;
    }
    
    .redesContainer {
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }
    
    .h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
    }
    
    .h5 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
    }
    
    .h6 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
        color: #fff;
    }
    
    .h7 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
    }
    
    .imageCarouselWithMargin {
        margin-right: 20px; /* Margen derecho de 300px */
        margin-left: 20px; /* Margen derecho de 300px */
        background-color: #aac6ff;
        border-radius: 30px;
      }
    
      .nuevoMiembro {
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
        text-align: center;
      }
    
      .h3Bien {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal; /* Restablece la negrita para h3 */
        font-size: 14px;
      }
    
      .formacionContainer {
        margin-top: 50px;
        margin-bottom: 0px;
        width: 100%; 
        height: 250px; 
        background-image: url('../assets/IMG_1209\ 2.jpg'); /* Ruta de la imagen de fondo */
        background-size: cover; /* Escala la imagen para que cubra todo el contenedor */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center; /* Centra la imagen en el contenedor */
        align-items: center;
        flex-direction: column;
        display: flex;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); /* Ajusta el color y la opacidad según tus preferencias */
          }
      }
    
      .h7Form {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #fff; 
        text-align: center;
        position: relative;
    }
    
    .h8Form {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: #fff;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 18%;
    }
    
    .enlaceFormacion {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        text-decoration: none; 
        transition: underline 0.3s ease; /* Agrega una transición para suavizar el cambio */
    }
    
    .enlaceFormacion:hover {
        text-decoration: underline; /* Subraya el texto al pasar el cursor por encima */
    }
    
    .versiculoContainer {
        position: relative;
        width: 100%;
        height: 300px;
        background-color: rgba(52, 77, 214, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .versiculoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/313406135_437505558548539_1126158959325515491_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 55%;
        z-index: -1;
    }
    
    .p2 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .p3 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
    }
}