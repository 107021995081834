
.cardContainer { 
    display: flex;
    justify-content: space-around; /* Distribuye uniformemente las tarjetas dentro del contenedor */
    align-items: center;
    width: 100%; /* Asegura que las tarjetas ocupen el 100% del ancho disponible */
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  @media (max-width: 425px) {
    .cardContainer { 
      display: flex;
      flex-direction: column;
      justify-content: space-around; /* Distribuye uniformemente las tarjetas dentro del contenedor */
      align-items: center;
      width: 100%; /* Asegura que las tarjetas ocupen el 100% del ancho disponible */
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
