.imageContainer {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(0, 0, 0, 0.45);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; /* Centra horizontalmente los elementos hijos */
}

.imageContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/332213114_1255162972014085_8459134046311574829_n.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 70%;
    z-index: -1;
}

.h1 {
    font-size: 54px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: relative;
}

.h2 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    text-align: center;
    position: relative;
}

.h3 {
    font-size: 44px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #383838;
    text-align: center;
    position: relative;
    margin-right: 100px;
    transition: transform 0.3s ease;
}

.h3:hover {
    transform: scale(1.2);
}

.h4 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #383838;
    text-align: center;
    position: relative;
}


.donacionContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.nequiContainer {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.nequiContainer img {
    transition: transform 0.3s ease;
}

.nequiContainer img:hover {
    transform: scale(1.2);
}

@media (max-width: 1024px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 400px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.45);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/332213114_1255162972014085_8459134046311574829_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 70%;
        z-index: -1;
    }
    
    .h1 {
        font-size: 44px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h3 {
        font-size: 38px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #383838;
        text-align: center;
        position: relative;
        margin-right: 100px;
        transition: transform 0.3s ease;
    }
    
    .h3:hover {
        transform: scale(1.2);
    }
    
    .h4 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        color: #383838;
        text-align: center;
        position: relative;
    }
    
    
    .donacionContainer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .nequiContainer {
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }
    
    .nequiContainer img {
        transition: transform 0.3s ease;
    }
    
    .nequiContainer img:hover {
        transform: scale(1.2);
    }
}

@media (max-width: 768px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 350px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.45);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/332213114_1255162972014085_8459134046311574829_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 70%;
        z-index: -1;
    }
    
    .h1 {
        font-size: 38px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h3 {
        font-size: 30px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #383838;
        text-align: center;
        position: relative;
        margin-right: 100px;
        transition: transform 0.3s ease;
    }
    
    .h3:hover {
        transform: scale(1.2);
    }
    
    .h4 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        color: #383838;
        text-align: center;
        position: relative;
    }
    
    
    .donacionContainer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .nequiContainer {
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }
    
    .nequiImage {
        width: 30%;
    }
}

@media (max-width: 425px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.45);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/332213114_1255162972014085_8459134046311574829_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 70%;
        z-index: -1;
    }
    
    .h1 {
        font-size: 32px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h3 {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #383838;
        text-align: center;
        position: relative;
        margin-right: 100px;
    }
    
    .h4 {
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        color: #383838;
        text-align: center;
        position: relative;
    }
    
    .donacionContainer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .nequiContainer {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }
    
    .nequiImage {
        width: 30%;
    }
}
