.cardContainer {
  width: 300px;
  border-radius: 30px;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-right: -160px; */
}
.cardContainer:hover {
  transform: scale(1.1);
}
.backgroundImage {
  width: 350px;
  height: 350px;
  background-size: cover; /* O ajusta según tus necesidades */
  border-radius: 15px;
  display: flex;
}

.content {
  background-color: #e5edff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: normal;
  margin: 0px 0;
  color: #666;
}
 
@media (max-width: 1024px) {
  .cardContainer {
    width: 250px;
    border-radius: 30px;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: -160px; */
  }
  .cardContainer:hover {
    transform: scale(1.1);
  }
  .backgroundImage {
    width: 300px;
    height: 300px;
    background-size: cover; /* O ajusta según tus necesidades */
    border-radius: 15px;
    display: flex;
  }
  
  .content {
    background-color: #e5edff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: normal;
    margin: 0px 0;
    color: #666;
  }
}

@media (max-width: 768px) {
  .cardContainer {
    width: 200px;
    border-radius: 30px;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: -160px; */
  }
  .cardContainer:hover {
    transform: scale(1.1);
  }
  .backgroundImage {
    width: 220px;
    height: 220px;
    background-size: cover; /* O ajusta según tus necesidades */
    border-radius: 15px;
    display: flex;
  }
  
  .content {
    background-color: #e5edff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: normal;
    margin: 0px 0;
    color: #666;
  }
}

@media (max-width: 425px) {
  .cardContainer {
    width: 250px;
    border-radius: 30px;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .cardContainer:hover {
    transform: scale(1.1);
  }
  .backgroundImage {
    width: 250px;
    height: 250px;
    background-size: cover; /* O ajusta según tus necesidades */
    border-radius: 15px;
    display: flex;
  }
  
  .content {
    background-color: #e5edff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: normal;
    margin: 0px 0;
    color: #666;
  }
}