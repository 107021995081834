.imageContainer {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; 
}

.imageContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/IMG_1124\ 2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.h1 {
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    text-align: center;
    position: relative;
}

.h2 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #fff;
    text-align: center;
    position: relative;
    margin-left: 120px;
    margin-right: 120px;
}

.h3 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #000000;
}

.h4 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #000000;
    text-align: justify;
}

.h5 {
    font-size: 88px;
    font-family: 'Oswald', sans-serif;
    color: #fff;
    text-align: center;
    position: relative;
}


.evangelizarContainer {
    display: flex;
    align-items: center;
    gap: 70px;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 120px;
    margin-left: 120px;
    background-color: #c6e8ff90;
    padding: 50px;
}

.evangelizarContainer {
    position: relative;
    overflow: hidden;
}

.evangelizarContainer img {
    width: 430px;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 20%;
    border: 30px solid #c6e8ff
}

.evangelizarContainer:hover img {
    transform: scale(1.2);
}


.horario {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 300px;
    margin-left: 300px;
}

.empoderandoContainer {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 50px;
    position: relative;
    width: 100%;
    height: 750px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(255, 0, 0, 0.211);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; 
}

.empoderandoContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/Empoderando.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.empoderandoTexto {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-right: 150px;
    margin-left: 150px;
}

@media (max-width: 1024px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 400px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/IMG_1124\ 2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .h1 {
        font-size: 48px;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 120px;
        margin-right: 120px;
    }
    
    .h3 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
    }
    
    .h4 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
    }
    
    .h5 {
        font-size: 68px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    
    .evangelizarContainer {
        display: flex;
        align-items: center;
        gap: 70px;
        border-radius: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 120px;
        margin-left: 120px;
        background-color: #c6e8ff90;
        padding: 50px;
    }
    
    .evangelizarContainer {
        position: relative;
        overflow: hidden;
    }
    
    .evangelizarContainer img {
        width: 230px;
        height: auto;
        transition: transform 0.3s ease;
        border-radius: 20%;
        border: 10px solid #c6e8ff
    }
    
    .evangelizarContainer:hover img {
        transform: scale(1.2);
    }
    
    
    .horario {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 300px;
        margin-left: 300px;
    }
    
    .empoderandoContainer {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 20px;
        position: relative;
        width: 100%;
        height: 550px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(255, 0, 0, 0.211);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .empoderandoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/Empoderando.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .empoderandoTexto {
        margin-top: 70px;
        margin-bottom: 70px;
        margin-right: 150px;
        margin-left: 150px;
    }
}

@media (max-width: 768px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 350px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/IMG_1124\ 2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .h1 {
        font-size: 38px;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 120px;
        margin-right: 120px;
    }
    
    .h3 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
    }
    
    .h4 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
    }
    
    .h5 {
        font-size: 58px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    
    .evangelizarContainer {
        display: flex;
        align-items: center;
        gap: 40px;
        border-radius: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 50px;
        margin-left: 50px;
        background-color: #c6e8ff90;
        padding: 50px;
    }
    
    .evangelizarContainer {
        position: relative;
        overflow: hidden;
    }
    
    .evangelizarContainer img {
        width: 230px;
        height: auto;
        transition: transform 0.3s ease;
        border-radius: 20%;
        border: 10px solid #c6e8ff
    }
    
    .evangelizarContainer:hover img {
        transform: scale(1.2);
    }
    
    
    .horario {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 100px;
        margin-left: 100px;
    }
    
    .empoderandoContainer {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 20px;
        position: relative;
        width: 100%;
        height: 450px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(255, 0, 0, 0.211);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .empoderandoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/Empoderando.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .empoderandoTexto {
        margin-top: 70px;
        margin-bottom: 70px;
        margin-right: 30px;
        margin-left: 30px;
    }
}

@media (max-width: 425px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 250px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../assets/IMG_1124\ 2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .h1 {
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 11px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .h3 {
        font-size: 11px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
    }
    
    .h4 {
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
    }
    
    .h5 {
        font-size: 38px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    
    .evangelizarContainer {
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        margin-left: 20px;
        background-color: #c6e8ff90;
        padding: 20px;
    }
    
    .evangelizarContainer {
        position: relative;
        overflow: hidden;
    }
    
    .evangelizarContainer img {
        width: 130px;
        height: auto;
        border-radius: 20%;
        border: 5px solid #c6e8ff
    }
    
    /* .evangelizarContainer:hover img {
        transform: scale(1.2);
    } */
    
    
    .horario {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 100px;
        margin-left: 100px;
    }
    
    .empoderandoContainer {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 20px;
        position: relative;
        width: 100%;
        height: 350px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(255, 0, 0, 0.211);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .empoderandoContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/Empoderando.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
    
    .empoderandoTexto {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 40px;
        margin-left: 40px;
    }
}









