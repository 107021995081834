.imageContainer {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; /* Centra horizontalmente los elementos hijos */
}

.imageContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/326363539_890515505428918_5757183746271280915_n.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 25%;
    z-index: -1;
    transform: scale(1.2);
}


.descripcion img {
    width: 800px;
    height: auto;
    border-radius: 0%;
    border: 4px solid #c6e8ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
}

.descripcion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.h1 {
    font-size: 72px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: relative;
}

.h2 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #fff;
    text-align: center;
    position: relative;
    margin-left: 200px;
    margin-right: 200px;
}

.h3 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #000000;
}

.h4 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #000000;
    text-align: justify;
    margin-top: 50px;
    margin-left: 150px;
    margin-right: 150px;
}

.h5 {
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
    color: #fff;
    text-align: center;
    position: relative;
}

.familiaContainer {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 50px;
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
    background-color: rgba(0, 255, 157, 0.3);
    display: flex; /* Activa el modelo de caja flexible */
    flex-direction: column; /* Apila los elementos hijos verticalmente */
    justify-content: center; /* Centra verticalmente los elementos hijos */
    align-items: center; 
}

.familiaContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/FamiliaMinisterial2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

@media (max-width: 1024px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 500px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/326363539_890515505428918_5757183746271280915_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 25%;
        z-index: -1;
        transform: scale(1.2);
    }
    
    
    .descripcion img {
        width: 600px;
        height: auto;
        border-radius: 0%;
        border: 4px solid #c6e8ff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    }
    
    .descripcion {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    .h1 {
        font-size: 68px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .h3 {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
    }
    
    .h4 {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
        margin-top: 50px;
        margin-left: 150px;
        margin-right: 150px;
    }
    
    .h5 {
        font-size: 50px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .familiaContainer {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 46px;
        position: relative;
        width: 100%;
        height: 600px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 255, 157, 0.3);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .familiaContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/FamiliaMinisterial2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
}

@media (max-width: 768px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 350px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/326363539_890515505428918_5757183746271280915_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 25%;
        z-index: -1;
        transform: scale(1.2);
    }
    
    
    .descripcion img {
        width: 500px;
        height: auto;
        border-radius: 0%;
        border: 4px solid #c6e8ff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    }
    
    .descripcion {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .h1 {
        font-size: 60px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .h3 {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
    }
    
    .h4 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
        margin-top: 50px;
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .h5 {
        font-size: 35px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .familiaContainer {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 46px;
        position: relative;
        width: 100%;
        height: 450px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 255, 157, 0.3);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .familiaContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/FamiliaMinisterial2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
}

@media (max-width: 425px) {
    .imageContainer {
        position: relative;
        width: 100%;
        height: 350px;
        overflow: hidden; /* Asegura que el pseudoelemento no salga del contenedor */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; /* Centra horizontalmente los elementos hijos */
    }
    
    .imageContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/326363539_890515505428918_5757183746271280915_n.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 25%;
        z-index: -1;
        transform: scale(1.2);
    }
    
    
    .descripcion img {
        width: 300px;
        height: auto;
        border-radius: 0%;
        border: 4px solid #c6e8ff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    }
    
    .descripcion {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: center;
    }
    
    .h1 {
        font-size: 50px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .h2 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #fff;
        text-align: center;
        position: relative;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .h3 {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #000000;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .h4 {
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #000000;
        text-align: justify;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .h5 {
        font-size: 20px;
        font-family: 'Oswald', sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
    }
    
    .familiaContainer {
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 30px;
        position: relative;
        width: 100%;
        height: 300px;
        background-color: rgba(0, 255, 157, 0.3);
        display: flex; /* Activa el modelo de caja flexible */
        flex-direction: column; /* Apila los elementos hijos verticalmente */
        justify-content: center; /* Centra verticalmente los elementos hijos */
        align-items: center; 
    }
    
    .familiaContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/FamiliaMinisterial2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
    }
}