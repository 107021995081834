
.formandoContainer {
    background-color: rgba(179, 196, 249, 0.3);
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 120px;
    margin-right: 120px;
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.formandoContainer:hover {
    transform: scale(1.12); 
}

.enamorarteContainer {
    display: flex;
    margin-top: 100px;
    margin-bottom: 30px;
    margin-left: 120px;
    margin-right: 120px;
  }
  
  .enamorarteImage {
    width: 50%; /* o el porcentaje que desees */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }

  .enamorarteImage:hover {
    transform: scale(1.07);
  }
  
  .enamorarteText {
    padding-left: 30px; /* o el espacio que desees entre la imagen y el texto */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .avanzaContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 30px;
    margin-left: 120px;
    margin-right: 120px;
  }
  
  .avanzaImage {
    width: 55%; /* o el porcentaje que desees */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }

  .avanzaText {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #bdd0e4ea;
    border-radius: 30px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.avanzaText:hover {
    transform: scale(1.15); 
}

.transformaContainer {
    display: flex;
    margin-top: 100px;
    margin-bottom: 30px;
    margin-left: 120px;
    margin-right: 120px;
  }

  .transformaImage {
    width: 50%; /* o el porcentaje que desees */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }

  .transformaImage:hover {
    transform: scale(1.1); 
}
  
  .transformaText {
    padding-right: 30px; /* o el espacio que desees entre la imagen y el texto */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


.h1 {
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #272b2f;
    text-align: center;
}

.h2 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #272b2f;
    text-align: center;
}

.h3 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #bdd0e4;
    text-align: center;
}

@media (max-width: 1024px) {

.formandoContainer {
  background-color: rgba(179, 196, 249, 0.3);
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.formandoContainer:hover {
  transform: scale(1.12); 
}

.enamorarteContainer {
  display: flex;
  margin-top: 100px;
  margin-bottom: 30px;
  margin-left: 120px;
  margin-right: 120px;
}

.enamorarteImage {
  width: 50%; /* o el porcentaje que desees */
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.enamorarteImage:hover {
  transform: scale(1.07);
}

.enamorarteText {
  padding-left: 30px; /* o el espacio que desees entre la imagen y el texto */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avanzaContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 60px;
  margin-right: 60px;
}

.avanzaImage {
  width: 55%; /* o el porcentaje que desees */
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.avanzaText {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #bdd0e4ea;
  border-radius: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.avanzaText:hover {
  transform: scale(1.15); 
}

.transformaContainer {
  display: flex;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: 60px;
  margin-right: 60px;
}

.transformaImage {
  width: 50%; /* o el porcentaje que desees */
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.transformaImage:hover {
  transform: scale(1.1); 
}

.transformaText {
  padding-right: 30px; /* o el espacio que desees entre la imagen y el texto */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.h1 {
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #272b2f;
  text-align: center;
}

.h2 {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  color: #272b2f;
  text-align: center;
}

.h3 {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  color: #bdd0e4;
  text-align: center;
}
}

@media (max-width: 768px) {
  .formandoContainer {
    background-color: rgba(179, 196, 249, 0.3);
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .formandoContainer:hover {
    transform: scale(1.12); 
  }
  
  .enamorarteContainer {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 80px;
    margin-right: 80px;
  }
  
  .enamorarteImage {
    width: 50%; /* o el porcentaje que desees */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .enamorarteImage:hover {
    transform: scale(1.07);
  }
  
  .enamorarteText {
    padding-left: 30px; /* o el espacio que desees entre la imagen y el texto */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .avanzaContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 60px;
    margin-right: 60px;
  }
  
  .avanzaImage {
    width: 55%; /* o el porcentaje que desees */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
  
  .avanzaText {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #bdd0e4ea;
    border-radius: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .avanzaText:hover {
    transform: scale(1.15); 
  }
  
  .transformaContainer {
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 60px;
    margin-right: 60px;
  }
  
  .transformaImage {
    width: 50%; /* o el porcentaje que desees */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .transformaImage:hover {
    transform: scale(1.1); 
  }
  
  .transformaText {
    padding-right: 30px; /* o el espacio que desees entre la imagen y el texto */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  
  .h1 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #272b2f;
    text-align: center;
  }
  
  .h2 {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #272b2f;
    text-align: center;
  }
  
  .h3 {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: #bdd0e4;
    text-align: center;
  }
  }

  @media (max-width: 425px) {
    .formandoContainer {
      background-color: rgba(179, 196, 249, 0.3);
      border-radius: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 50px;
      margin-right: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    }
    
    .enamorarteContainer {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 20px;
      margin-right: 20px;
      gap: 20px;
    }
    
    .enamorarteImage {
      width: 100%; /* o el porcentaje que desees */
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    }
    .enamorarteText {
      display: flex;
      justify-content: center;
      margin-left: -20px;
    }
    
    .avanzaContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 30px;
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .avanzaImage {
      width: 100%; /* o el porcentaje que desees */
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    }
    
    .avanzaText {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #bdd0e4ea;
      border-radius: 30px;
      padding-bottom: 30px;
      padding-left: 50px;
      padding-right: 50px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    }
    
    .transformaContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }
    
    .transformaImage {
      width: 100%; /* o el porcentaje que desees */
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    }
    
    .transformaText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: -40px;
      margin-bottom: 10px;
    }
    
    
    .h1 {
      font-size: 18px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      color: #272b2f;
      text-align: center;
    }
    
    .h2 {
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      color: #272b2f;
      text-align: center;
    }
    
    .h3 {
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      color: #bdd0e4;
      text-align: center;
    }
  }
